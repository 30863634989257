import React from 'react'
import MainLayout from '../../components/MainLayout'

const ForgotPasswordPage = () => {
  return (
    <MainLayout>
    <div className='min-h-[50vh] font-bold italic text-center'>
      forgot password page coming soon...
    </div>
    </MainLayout>
    
  )
}

export default ForgotPasswordPage
