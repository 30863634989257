import React from 'react';
import MainLayout from '../../components/MainLayout';

const AboutPage = () => {
  return (
    <MainLayout>
    <div className='min-h-[50vh] font-bold italic text-center'>
     About page coming soon...
    </div>
    </MainLayout>
    
  )
}

export default AboutPage
