import React from 'react';
import MainLayout from '../../components/MainLayout';

const ContactPage = () => {
  return (
    <MainLayout>
    <div className='min-h-[50vh] font-bold italic text-center'>
      Contact page coming soon...
    </div>
    </MainLayout>
    
  )
}

export default ContactPage
